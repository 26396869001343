class circleProgress {
  constructor(params) {
    /*
                需要传入的参数
                el canvas 元素
                progress  真实的进度比例 0 - 1  之间 100% 就是1；
                number 当前进度表示的数额
            */
    const { el, number } = params;
    const parentElement = document.querySelector(el).parentElement;
    //获取canvas 元素 及其 2d context
    const cav = document.querySelector(el);
    cav.setAttribute("width", parentElement.offsetWidth);
    cav.setAttribute("height", parentElement.offsetHeight);
    this.ctx = cav.getContext("2d");
    // 获取canvas 元素的宽高信息 用来计算圆形进度的 圆点坐标
    const { width, height } = cav.getBoundingClientRect();
    this.w = 300;
    this.h = 300;
    this.x = width / 2;
    this.y = height / 2;
    this.number = number;
    this.r = parentElement.offsetWidth / 2 - 12;
    // Math.PI / 180 是很直观的度数换算公式 -> 度数 *  Math.PI / 180;
    this.angle = Math.PI / 180;

    // 圆形进度的开始 和 结束 路径角度度数
    this.beginDeg = 135;
    this.endDeg = 45;
    // 计算出真实进度条的结束 路径 角度度数
    this.progress = this.calculatePercentage(this.number);
    this.progressDeg =
      this.beginDeg + (360 - this.beginDeg + this.endDeg) * this.progress;
    this.aniDeg = this.beginDeg;

    this.move();
  }
  calculatePercentage(value, min = 16, max = 31) {
    return (value - min) / (max - min);
  }

  /*
            获取圆形上某个点的xy坐标
            公式：
            x = 圆心x坐标 + 半径 * Math.cos(点的角度*Math.PI/180)
            y = 圆心y坐标 + 半径 * Math.sin(点的角度*Math.PI/180)
        */
  getPointPos(deg) {
    const { ctx, x, y, r, angle } = this;
    return {
      xPos: x + r * Math.cos(deg * angle),
      yPos: y + r * Math.sin(deg * angle),
    };
  }

  // 绘制文字
  drawFont(font) {
    const { ctx, w, h } = this;
    // 获取文字的宽度 用已计算文字的x坐标 居中显示
    ctx.font = "bold 30px arial";
    const fontW = ctx.measureText(font).width;
    ctx.fillStyle = "#00aeef";
    ctx.fillText(font, (w - fontW) / 2, h / 2 + 12);
  }

  // 绘制圆形
  drawCircle(color, endDeg, startCOlor) {
    const { ctx, x, y, r, angle } = this;
    ctx.beginPath();
    ctx.lineCap = "round";
    ctx.lineWidth = 6;
    // 渐变色 - 可自定义
    var linGrad = ctx.createLinearGradient(x - r - 6, y, x + r + 6, y);
    linGrad.addColorStop(0.0, startCOlor);
    linGrad.addColorStop(1.0, color);
    ctx.strokeStyle = linGrad;
    ctx.arc(x, y, r, this.beginDeg * angle, endDeg * angle);
    ctx.stroke();
    ctx.closePath();
  }

  // 绘制圆点
  drawPoint() {
    const { ctx, x, y, r, angle, aniDeg } = this;
    // 绘制圆形
    ctx.beginPath();
    ctx.fillStyle = "#FFFFFF";
    const { xPos, yPos } = this.getPointPos(aniDeg);
    ctx.arc(xPos, yPos, 13, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();
  }

  // 动画绘制
  move() {
    const { ctx, x, y, r, angle, w, h, progressDeg, number } = this;
    let font = 0;
    let last = false;
    const draw = () => {
      // canvas 动画美帧绘制前 都需要把上一帧清除掉
      ctx.clearRect(0, 0, w, h);
      // 绘制背景圆弧
      this.drawCircle("#ebe3e3", this.endDeg, "#ebe3e3");
      // 绘制真实进度圆弧
      console.log("this.aniDeg----->", this.aniDeg);
      this.drawCircle("#FF2F2F", this.aniDeg, "#FA9999");
      // 绘制真实进度圆点
      this.drawPoint();
      // 缓动公式  A = A + (B - A) / 速率；
      // 需要注意的是公式会无穷接近B 所以需要加一个临界判断；
      this.aniDeg = this.aniDeg + (progressDeg - this.aniDeg) / 20;
      console.log("aniDeg>", this.aniDeg);
      font = font + (number - font) / 20;
      // this.drawFont(Math.floor(font));
      if (last) {
        return;
      }
      console.log("progressDeg----->", progressDeg);
      if (progressDeg - this.aniDeg > 1) {
        //使用requestAnimationFrame来实现动画 效果会更加细腻
        this.aniDeg = progressDeg;
        requestAnimationFrame(draw);
      } else {
        // 临界判断 最后一次绘制 达到目标进度；
        last = true;
        this.aniDeg = progressDeg;
        font = number;
        draw();
      }
    };
    draw();
  }
}
export default circleProgress;
