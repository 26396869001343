<template>
  <div class="airConditionerSwitch">
    <div class="switch">
      <img src="./img/switch.png" alt="" />
    </div>
    <!-- 进度条 -->
    <div class="progress-bar">
      <canvas id="canvas"></canvas>
      <div class="info">
        <div class="pattern">制冷</div>
        <div class="temperature">
          <span>{{ this.canvasParams.number }}</span>
          <span>°C</span>
        </div>
        <div class="wind-speed">风速{{ WindNumber }}</div>
      </div>
      <!-- 温度 -->
      <div
        :class="['item', `item_${index}`]"
        v-for="(item, index) in test"
        :key="index"
      >
        <div>
          {{ item }}
        </div>
        <div class="dot"></div>
      </div>
    </div>

    <div class="content">
      <!-- 控件 -->
      <div class="control">
        <div class="title temperature">
          <div class="label">温度</div>
          <div class="line"></div>
          <div class="value">{{ this.canvasParams.number }}°C</div>
        </div>
        <div class="temperature-control">
          <div
            class="left"
            :style="{ color: value > 16 ? '#FFFFFF' : '#fe4416' }"
          >
            16
          </div>
          <div
            class="right"
            :style="{ color: value == 31 ? '#FFFFFF' : '#fe4416' }"
          >
            31
          </div>
          <van-slider
            @change="handelChangeTemperature"
            active-color="#FE4416"
            inactive-color="#F2E0E0"
            v-model="value"
            bar-height="34"
            :min="16"
            :max="31"
          />
        </div>
        <div class="title temperature">
          <div class="label">风速</div>
          <div class="line"></div>
          <div class="value">{{ WindNumber }}</div>
        </div>
        <div class="temperature-control wind-control">
          <div
            class="left"
            :style="{ color: WindNumber > 16 ? '#FFFFFF' : '#FFA200' }"
          >
            16
          </div>
          <div
            class="right"
            :style="{ color: WindNumber == 31 ? '#FFFFFF' : '#FFA200' }"
          >
            31
          </div>
          <van-slider
            @change="handelChangeWind"
            active-color="#FFBC47"
            inactive-color="#FAF2E7 "
            v-model="WindNumber"
            bar-height="34"
            :min="16"
            :max="31"
          />
        </div>
      </div>
      <!-- 模式 -->
      <div class="pattern">
        <div class="type">
          <div class="label">模式</div>
          <div class="line"></div>
          <div class="value">制冷</div>
        </div>
        <div class="item">
          <div>
            <img src="./img/cold.png" alt="" />
          </div>
          <div>
            <img src="./img/heating.png" alt="" />
          </div>
          <div>
            <img src="./img/wind.png" alt="" />
          </div>
          <div>
            <img src="./img/wet.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/utils/utils";
import Vue from "vue";
import { Slider } from "vant";

Vue.use(Slider);
import circleProgress from "./canvas.js";
export default {
  data() {
    return {
      test: [16, 18, 20, 22, 24, 26, 28, 30, 31],
      value: 16,
      canvasParams: {
        el: "#canvas",
        number: 16,
      },
      WindNumber: 16,
    };
  },
  mounted() {
    // const progress = this.calculatePercentage(20);
    // params.progress = progress;
    // console.log("progress----->", progress);
    new circleProgress(this.canvasParams);
    //   const res = {
    //   el: "#canvas",
    //   // progress: 0.4,
    //   number: 26,
    // };
    // new circleProgress(res);
  },
  methods: {
    handelChangeTemperature(value) {
      debounce(() => {
        this.canvasParams.number = value;
        new circleProgress(this.canvasParams);
      }, 300)();
    },
    handelChangeWind(value) {
      this.WindNumber = value;
    },
    calculatePercentage(value, min = 16, max = 31) {
      return (value - min) / (max - min);
    },
  },
};
</script>
<style lang="less">
.airConditionerSwitch {
  width: 100%;
  min-height: 100vh;
  background: #f5eeec;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  .switch {
    z-index: 99;
    position: fixed;
    top: 46px;
    right: 0;
    width: 166px;
    height: 166px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .progress-bar {
    width: 508px;
    height: 508px;
    position: relative;
    margin-top: 170px;
    box-sizing: border-box;
    // border: 1px solid #000;
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      width: 392px;
      height: 392px;
      background: linear-gradient(180deg, #f9f4f4 1%, #f4efed 100%);
      box-shadow: 6px 14px 38px 0px #e0b5b5;
      transform: translate(-50%, -50%);
      .pattern {
        font-size: 36px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
        line-height: 50px;
      }
      .temperature {
        display: flex;
        align-items: flex-start;
        span {
          font-size: 140px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 196px;
          &:last-child {
            font-size: 40px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 56px;
          }
        }
      }
      .wind-speed {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 26px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #f4a6a6;
        width: 137px;
        height: 50px;
        border-radius: 50px;
        border: 2px solid #f4a6a6;
        box-sizing: border-box;
      }
    }
    .item {
      position: absolute;
      left: 0;
      top: -10%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 24px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #f4a6a6;
      line-height: 34px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      .dot {
        margin-top: 12px;
        width: 10px;
        height: 10px;
        background: #e9aaaa;
        border-radius: 50%;
      }
      &.item_0 {
        left: 0;
        top: 83%;
        transform: rotate(234deg);
      }
      &.item_1 {
        left: -12%;
        top: 50%;
        transform: rotate(268deg);
      }
      &.item_2 {
        left: -5%;
        top: 12%;
        transform: rotate(312deg);
      }
      &.item_3 {
        left: 20%;
        top: -10%;
        transform: rotate(340deg);
      }
      &.item_4 {
        top: -15%;
        left: 59%;
        transform: rotate(18deg);
      }
      &.item_5 {
        left: 89%;
        top: 0%;
        transform: rotate(45deg);
      }
      &.item_6 {
        left: 107%;
        top: 31%;
        transform: rotate(74deg);
      }
      &.item_7 {
        left: 105%;
        top: 65%;
        transform: rotate(108deg);
      }
      &.item_8 {
        left: 92%;
        top: 83%;
        transform: rotate(134deg);
      }
    }
  }
  .content {
    margin-top: 102px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
    .control {
      margin-bottom: 24px;
      width: 100%;
      padding: 46px 38px 46px 38px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 16px;
      .title {
        margin-bottom: 26px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        .label,
        .value {
          position: relative;
          font-size: 30px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 42px;
        }
        .value {
          color: #ff2323;
        }

        .line {
          margin: 0 15px;
          width: 3px;
          height: 34px;
          background-color: #000;
        }
      }
      .temperature-control {
        padding: 8px 12px;
        box-sizing: border-box;
        height: 50px;
        background: linear-gradient(180deg, #e8e8e8 0%, #f7f7f7 100%);
        border-radius: 29px;
        margin-bottom: 42px;
        position: relative;
        &.wind-control {
          margin-bottom: 0;
        }
        .left {
          z-index: 9999;
          position: absolute;
          left: 8%;
          font-size: 24px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #fe4416;
          line-height: 34px;
        }
        .right {
          z-index: 9999;
          position: absolute;
          right: 8%;
          font-size: 24px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #fe4416;
          line-height: 34px;
        }
        .van-slider {
          height: 100% !important;
          // .van-slider__button-wrapper {
          //   right: 3%;
          // }
          .van-slider__button {
            width: 42px;
            height: 42px;
            background: #ffffff;
            box-shadow: 0px 4px 8px 0px rgba(227, 231, 241, 0.5);
          }
        }
      }
    }
    .pattern {
      box-sizing: border-box;
      padding: 32px 38px 54px 38px;
      background: #ffffff;
      border-radius: 16px;
      .type {
        display: flex;
        align-items: center;
        .label {
          font-size: 30px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 42px;
        }
        .line {
          margin: 0 15px;
          width: 3px;
          height: 34px;
          background-color: #000;
        }
        .value {
          font-size: 30px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #fe4416;
          line-height: 42px;
        }
      }
      .item {
        box-sizing: border-box;
        margin-top: 30px;
        display: flex;
        align-items: center;
        gap: 46px;
        flex-wrap: wrap;
        div {
          width: 90px;
          height: 90px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
